import './Footer.scss';
import logo from '../images/logo.png';
import Button from '../components/Button';

function Footer() {
    const toICP = (e:any) => {
        window.location.href="https://beian.miit.gov.cn/";
    };

    return (
        <div id="footer" className="Footer">
            <div className="Footer-line"></div>
            <div><img src={logo} className="Footer-logo" alt="logo" /></div>
            <div className="Footer-content">
                <div className="Footer-content-desc">
                    <div>请与我们联系以便于我们提供更好更准确的服务</div>
                    <Button onclick={toICP}>沪ICP备19036842号-1</Button>
                </div>
                <div className="Footer-content-menu">
                    <ul>
                        <li>联系电话：13818332692</li>
                        <li>电子邮件：cs@chengtingkeji.com</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
