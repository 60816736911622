
import mouse from '../images/mouse.svg';
import './Down.scss';
import {useState, useEffect} from 'react';

function Down() {
    const [display, setDisplay] = useState("inline-block");

    const handleDown = () => {
        const e = document.getElementById("footer");
        const scrollTop = document.documentElement.scrollTop ||
        document.body.scrollTop;
        if(window.innerHeight + scrollTop >= e.offsetTop) {
            setDisplay("none");
        } else {
            setDisplay("inline-block");
        }
    }
    

    useEffect(() => {
        // console.log('addEventListener handleDown');
        window.addEventListener("scroll", handleDown);
    }, []);

    return (
      <div id="down" style={{display:display}} className="Down"><img src={mouse} /></div>
    );
  }
  
  export default Down;
  