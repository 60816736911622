import SolutionItem from '../components/SolutionItem';
import './Solution.scss';

function Solution() {
    console.log("===solution===");
    const list = [
        {
          title: "金融", 
          icon: "finance-icon.svg", 
          desc: ["现货交易平台", "期货交易平台", "基金销售平台", "理财销售平台", "保险销售平台"]
        },
        {
          title: "综合服务", 
          icon: "comprehensive-icon.svg", 
          desc: ["电子商务平台", "供应链平台", "社区物业平台", "商场管理平台", "聊天通讯平台"]
        },
        {
          title: "区块链", 
          icon: "blockchain-icon.svg", 
          desc: ["公链联盟链私有链解决方案", "纯去中心化智能合约解决方案", "中心化结合去中心化解决方案", "原生和插件钱包解决方案", "定制化DAPP解决方案"]
        },
    ];
    return (
      <div id="solution" className="Solution">
          <div>
            <div className="App-title">行业解决方案</div>
          </div>
          <div className="Solution-list">
              {list.map((item, i) => {
                  return (
                    <SolutionItem key={i} data={item}></SolutionItem>
                  )
              })}
              <div></div>
          </div>
      </div>
    );
}
  
export default Solution;
  