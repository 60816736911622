import { FC, ReactElement, ReactNode } from 'react';
import {useNavigate} from 'react-router-dom';
import './button.scss';

interface ButtonProps {
    children: ReactNode;
    onclick?: React.MouseEventHandler<HTMLElement>;
    style?: Record<string, any>; 
}

const Button: FC<ButtonProps> = ({ children, onclick, style}: ButtonProps): ReactElement => {
    return (
        <div className="btn" style={style} onClick={onclick}>
            {children}
        </div>
    );
};

export default Button;
