import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import "./App.scss";
import Header from './page/Header';
import Home from "./page/Home";
import Solution from "./page/Solution";
import Footer from "./page/Footer";
import Down from "./page/Down";

const RouteApp: React.FC = () => {
    return (
      <div className="App">
        <Header isRouter={true} />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="*" element={<Navigate replace to="/home" />} />
        </Routes>
        <Footer />
      </div>
  );
};


const PageApp: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Home />
      <Solution />
      <Footer />
      <Down />
    </div>
  );
}

const App: React.FC = () => {
  let path = window.location.pathname;
  console.log(path);
  useEffect(()=>{
    setTimeout(()=>{
      document.getElementById("screen-loading").style.opacity="0";
      document.getElementById("screen-loading").style.zIndex="-1";
    }, 2000)
  },[])
  // if(path === '/__1__') {
    return <PageApp />;
  // } else {
  //   return <RouteApp />;
  // }
}

export default App;
