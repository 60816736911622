import { FC, ReactElement, useEffect, useState, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import logo from '../images/logo.png';
import menu from '../images/menu.svg';
import './Header.scss';

interface HeaderProps {
    isRouter?: boolean; 
}


let scrollY = 0;

const Header: FC<HeaderProps> = ({isRouter}:HeaderProps): ReactElement => {
    const [menuWidth, setMenuWidth] = useState('0');
    const [headerTop, setHeaderTop] = useState('0');
    const nav = useNavigate();

    const toHome = (e:any) => {
        if(isRouter) {
            nav("/home");
        } else {
            window.$goto("home");
        }
    }

    const toSolution = (e:any) => {
        if(isRouter) {
            nav("/solution");
        } else {
            window.$goto("solution");
        }
    };
    
    const toFooter = (e:any) => {
        window.$goto("footer");
    };

    const switchMenu = ()=> {
        if(menuWidth === '100%') {
            setMenuWidth('0');
        } else {
            setMenuWidth('100%');
        }
    }

    const switchHeaderTop = ()=> {
        const scrollTop = document.documentElement.scrollTop ||
        document.body.scrollTop;
        if(scrollTop < scrollY) {
            setHeaderTop('0');
        } else if(scrollTop > scrollY) {
            setHeaderTop('-70px');
        }
        scrollY = scrollTop;
    }

    
    const hiddenScroll = useCallback(() => {
        setMenuWidth('0');
        switchHeaderTop();
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", hiddenScroll);
    }, [hiddenScroll]);

    return (
        <header className="Header" style={{top:headerTop}}>
            <img src={logo} className="Header-logo" alt="logo" onClick={toHome} />
            <div className="Header-pc">
                <ul className="Header-menu">
                    <li onClick={toHome}>了解我们</li>
                    <li onClick={toSolution}>解决方案</li>
                    <li onClick={toFooter}>联系我们</li>
                </ul>
            </div>
            <div className="Header-mobile">
                <div><img src={menu} onClick={switchMenu} alt="" /></div>
                <div className='Header-menuwrap' style={{width:menuWidth}} >
                    <ul className="Header-menu">
                        <li onClick={toHome}>了解我们</li>
                        <li onClick={toSolution}>解决方案</li>
                        <li onClick={toFooter}>联系我们</li>
                    </ul>
                    <div className="Header-menu-btn">
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
