import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import './style/reset.scss';
import './style/iconfont/iconfont.css';
import App from './App';
// import {Scrollbars} from "react-custom-scrollbars";
import reportWebVitals from './reportWebVitals';


window.$goto = (id:string) => {
  const e = document.getElementById(id);
  const scrollTop = window.pageXOffset ||
  document.documentElement.scrollTop ||
  document.body.scrollTop;
  console.log(id, window.innerHeight, scrollTop, e.offsetTop)
  window.scrollTo({
      top: e.offsetTop-20,
      behavior: 'smooth'
  })
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
