import './Home.scss';
import Button from '../components/Button';


function Home() {
    const toFooter = (e:any) => {
        window.$goto("footer");
    };

    return (
        <div id="home">
            <div className="HomeWrap">
                <div className='Home-content'>
                    <div className="Home-content-title">始终保守对技术的热爱与追求</div>
                    <div className="Home-content-desc">为您的产品提供更加实用有效的服务，让技术为产品保驾护航!</div>
                    <div className="Home-content-btn">
                        <Button onclick={toFooter}>联系我们</Button>
                    </div>
                </div>
            </div>
            <div className='HomeListWrap'>
                <div className="HomeList">
                    <div>
                        <div className="App-title">快速了解我们</div>
                        <div className="App-desc">
                            完整项目周期高效迭代更新<br />
                            设计的专业性与前端交互的完美组合<br />
                            友好而严谨的代码逻辑与产品实现结合<br />
                            多行业一线经验丰富的坚实团队友好合作
                        </div>
                        <div className="App-more">
                            <Button>我们一直在学习进步</Button>
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li><div><i className="iconfont icon-brand" /></div><div>互联网电子商务解决方案</div> </li>
                            <li><div><i className="iconfont icon-numbering-device" /></div><div>大数据云计算AI解决方案</div></li>
                            <li><div><i className="iconfont icon-instant-message" /></div><div>行业服务解决方案</div></li>
                            <li><div><i className="iconfont icon-process-operate" /></div><div>区块链技术解决方案</div></li>
                            <li><div><i className="iconfont icon-target" /></div><div>个性定制技术解决方案</div></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;
