import { FC, ReactElement, ReactNode } from 'react';

interface SolutionItemProps {
    data : {
        icon: string;
        title: string;
        desc: string[];
    }
}

const SolutionItem: FC<SolutionItemProps> = ({data}: SolutionItemProps): ReactElement => {
    const iconUrl = require('../../images/'+data.icon);
    return (
        <div className="Solution-item">
            <div className="Solution-item-icon"><img src={iconUrl} /></div>
            <div className="Solution-item-title">{data.title}</div>
            <div className="Solution-item-line"></div>
            <div className="Solution-item-desc">
            {data.desc.map((value, index) => {
                return <li key={index}>{value}</li>
            })}
            </div>
            
        </div>
    );
};

export default SolutionItem;
